import React, { useState } from "react";
import { Form, Field, FormLayout, SubmitButton } from "@saas-ui/react";
import { Box, Heading, Text, Icon, Flex, Divider } from "@chakra-ui/react";
import { FiPhoneCall } from "react-icons/fi";

import "./CallForm.css";
import config from "../../config";

export default function CallForm() {
  const [callDetails, setCallDetails] = useState({
    data: undefined,
    email: undefined,
    broker_email: undefined,
    broker_phone: undefined
  });

  const handleChange = (e) => {
  const { name, value } = e.target;
  setCallDetails(prevState => ({
    ...prevState,
    [name]: value
  }));
  };
  
  const onSubmit = async (params) => {
   const postData = {
     data: callDetails.data,
     email: callDetails.email,
     broker_email: callDetails.broker_email,
     broker_phone: callDetails.broker_phone,
   };
    
   const requestOptions = {
     method: "POST",
     headers: {
       "Content-Type": "application/json", // Specify the content type as JSON
     },
     body: JSON.stringify(postData), // Convert the JavaScript object to JSON string
   };
    console.log(postData)
    await fetch(`${config.userAPI}/call`, requestOptions)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        console.log(data);
      })
      .catch((error) => {
        console.error("There was a problem with the fetch operation:", error);
      });
  };

  return (
    <div className="form-container">
      <Box className="form-box">
        <Flex alignItems="center" justifyContent="center" mb={4}>
          <Icon as={FiPhoneCall} w={8} h={8} color="teal.500" mr={2} />
          <Heading as="h2" size="lg" textAlign="center">
            Initiate a Call
          </Heading>
        </Flex>
        <Text mb={6} textAlign="center" color="gray.600">
          Please fill out the form below to initiate a call.
        </Text>
        <Divider mb={6} />
        <Form onSubmit={onSubmit}>
          <FormLayout>
            <Field
              name="data"
              label="Data"
              type="textarea"
              placeholder="Enter invoice details here..."
              onChange={handleChange}
              rules={{ required: true }}
            />

            <Field
              name="email"
              type="email"
              label="Your Email"
              placeholder="your.email@example.com"
              onChange={handleChange}
              rules={{ required: true }}
            />

            <Field
              name="broker_email"
              type="email"
              label="Broker's Email"
              placeholder="broker.email@example.com"
              onChange={handleChange}
              rules={{ required: true }}
            />

            <Field
              name="broker_phone"
              type="tel"
              label="Broker's Phone"
              placeholder="+1 (555) 123-4567"
              onChange={handleChange}
              rules={{ required: true }}
            />

            <SubmitButton
              style={{ backgroundColor: "#000000" }}
              className="submit-button"
              mt={4}
            >
              Initiate Call
            </SubmitButton>
          </FormLayout>
        </Form>
        <br></br>
        <p>
          <b>Realtime call logs</b>
        </p>
        <textarea></textarea>
      </Box>
    </div>
  );
}
